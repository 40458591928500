import React from "react";

import "./Modal.scss";
import { Button, ButtonVariant } from "../button";

/**
 * Contract for the data provided to the Modal component.
 */
interface IModalData {
	content?: undefined | null | string;
	buttons: {
		confirm: {
			text: string;
			onClick: () => void;
			disabled?: undefined | boolean;
		};
		cancel: {
			text: string;
			onClick: () => void;
			disabled?: undefined | boolean;
		};
	};
}

/**
 * Contract for the properties provided to the Modal component.
 */
interface IModalProps {
	title: string;
	data?: undefined | IModalData;
	children?: undefined | null | React.ReactNode | JSX.Element;
	open: boolean;
	wide?: undefined | boolean;
	fullScreen?: undefined | boolean;
}

/**
 * Modal component.
 *
 * @param props The properties of the component.
 * @returns
 */
export function Modal(props: IModalProps): JSX.Element {
	const { data, title, wide, fullScreen } = props;
	const modalWidth = wide ? "75vw" : fullScreen ? "100vw" : "";

	return props.open ? (
		<div className="modal">
			<div
				className={`modal__container ${
					fullScreen ? "full-screen" : ""
				}`}
			>
				<div className="modal__title">
					{title ?? "<undefined-title>"}
				</div>
				<div className="modal__content" style={{ width: modalWidth }}>
					{props?.children ?? data?.content}
				</div>
				{data?.buttons ? (
					<div className="modal__buttons">
						<Button
							className="modal__button modal__button_cancel"
							variant={ButtonVariant.Secondary}
							onClick={data.buttons.cancel.onClick}
							disabled={
								data.buttons.cancel?.disabled ?? undefined
							}
							text={data.buttons.cancel.text}
						></Button>
						<Button
							className="modal__button modal__button_confirm"
							onClick={data.buttons.confirm.onClick}
							disabled={
								data.buttons.confirm?.disabled ?? undefined
							}
							text={data.buttons.confirm.text}
						></Button>
					</div>
				) : null}
			</div>
			<div className="modal__backdrop"></div>
		</div>
	) : null;
}
