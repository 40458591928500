import React from "react";
import { InputText } from "./InputText";
import { InputNumber } from "./InputNumber";
import { InputFile } from "./InputFile";
import { TextArea } from "./TextArea";
import { AllowedInputTypes } from "enums";
import { InputFloat } from "./InputFloat";

/**
 * InputGroup props contract interface.
 */
declare type InputGroupProps = {
	label: string;
	value: string;
	id: string;
	className?: string;
	onChange?: undefined | ((event: any) => void);
	disabled?: undefined | boolean;
	name?: undefined | string;
	placeholder?: undefined | string;
	required?: undefined | boolean;
	minLength?: undefined | number;
	maxLength?: undefined | number;
	type?: undefined | AllowedInputTypes;
};

/**
 * InputGroup shared component.
 *
 * @param props
 * @returns
 */
const InputGroup: React.FC<InputGroupProps> = (props) => {
	const {
		label,
		value,
		id,
		className = "",
		onChange = () => {},
		disabled = false,
		name = id,
		placeholder = "",
		required = false,
		type = "text"
	} = props;

	const renderInput = () => {
		let inputRender = null;

		switch (type) {
			case AllowedInputTypes.number:
				inputRender = (
					<InputNumber
						id={id}
						value={value}
						name={name}
						onChange={(event) => onChange(event)}
						className={className}
						placeholder={placeholder}
						required={required}
						disabled={disabled}
						minLength={props?.minLength}
						maxLength={props?.maxLength}
					/>
				);
				break;

			case AllowedInputTypes.float:
				inputRender = (
					<InputFloat
						id={id}
						value={value}
						name={name}
						onChange={(event) => onChange(event)}
						className={className}
						placeholder={placeholder}
						required={required}
						disabled={disabled}
						type={type}
						minLength={props?.minLength}
						maxLength={props?.maxLength}
					/>
				);
				break;

			case AllowedInputTypes.file:
				inputRender = (
					<InputFile
						id={id}
						value={value}
						name={name}
						onChange={(event) => onChange(event)}
						className={className}
						placeholder={placeholder}
						required={required}
						disabled={disabled}
						type={type}
					/>
				);
				break;

			case AllowedInputTypes.textarea:
				inputRender = (
					<TextArea
						id={id}
						children={value}
						// name={name}
						onChange={(event) => onChange(event)}
						className={className}
						placeholder={placeholder}
						// required={required}
						disabled={disabled}
					/>
				);
				break;

			case AllowedInputTypes.text:
			default:
				inputRender = (
					<InputText
						id={id}
						value={value}
						name={name}
						onChange={(event) => onChange(event)}
						className={className}
						placeholder={placeholder}
						required={required}
						disabled={disabled}
						minLength={props?.minLength}
						maxLength={props?.maxLength}
						type={type}
					/>
				);
				break;
		}
		return inputRender;
	};

	return (
		<div>
			<label htmlFor={name}>
				{label}
				{required ? " *" : ""}
			</label>
			{renderInput()}
		</div>
	);
};

export { InputGroup, type InputGroupProps };
