import { combineReducers } from "@reduxjs/toolkit";
import userProfileReducer from "./user/userProfileReducer";

/* Reducer imports */
import listReducer from "./base/list";
import categoriesReducer from "./categories/reducer";
import appConfigReducer from "./appConfig/reducer";
import companiesReducer from "./companies/reducer";
import productsReducer from "./products/reducer";
import clientsReducer from "./clients/reducer";
import clientTablesReducer from "./clientTables/reducer";
import supplierReducer from "./suppliers/reducer";
import brandReducer from "./brands/reducer";
import landingPageReducer from "./landingPage/reducer";
import clientOrdersReducer from "./orders/client/reducer";
import clientOrderFormReducer from "./orderForm/reducer";
import stockListReducer from "./stock/list/reducer";
import aiModelProcessReducer from "./ai/model/reducer";
import aiChatReducer from "./ai/chat/reducer";
import storeReducer from "./store/reducer";
import stockMovementReducer from "./stock/movement/reducer";

/* Slice imports */
import {
	IAIModelProcessSlice,
	IAppConfigSlice,
	IBrandSlice,
	ICategorySlice,
	ICompanySlice,
	IClientSlice,
	IClientTableSlice,
	ILandingPageSlice,
	IClientOrderFormSlice,
	IProductSlice,
	IStockSlice,
	ISupplierSlice,
	IUserProfileSlice,
	IClientOrderListSlice,
	IAIChatSlice,
	IStockMovementSlice
} from "models";
import { IListState, IStoreSlice } from "interfaces/index";

/**
 * Contract for the Application State tree.
 */
interface IRootState {
	appConfig: IAppConfigSlice;
	clients: IClientSlice;
	clientOrders: IClientOrderListSlice;
	clientTables: IClientTableSlice;
	user: IUserProfileSlice;
	categories: ICategorySlice;
	products: IProductSlice;
	companies: ICompanySlice;
	list: IListState<any>;
	suppliers: ISupplierSlice;
	brands: IBrandSlice;
	landingPage: ILandingPageSlice;
	clientOrderForm: IClientOrderFormSlice;
	stockList: IStockSlice;
	aiModelProcesses: IAIModelProcessSlice;
	aiChats: IAIChatSlice;
	store: IStoreSlice;
	stockMovement: IStockMovementSlice;
	// Thunks
	// userActiveCompanyChange: InterceptorThunk;
	// someOtherReducer: any;
}

/**
 * Base structure of our Storage ecosystem.
 */
const RootReducer = combineReducers<IRootState>({
	appConfig: appConfigReducer,
	clients: clientsReducer,
	clientOrders: clientOrdersReducer,
	clientTables: clientTablesReducer,
	user: userProfileReducer,
	categories: categoriesReducer,
	products: productsReducer,
	companies: companiesReducer,
	list: listReducer,
	suppliers: supplierReducer,
	brands: brandReducer,
	landingPage: landingPageReducer,
	clientOrderForm: clientOrderFormReducer,
	stockList: stockListReducer,
	aiModelProcesses: aiModelProcessReducer,
	aiChats: aiChatReducer,
	store: storeReducer,
	stockMovement: stockMovementReducer
	// Thunks
	// userActiveCompanyChange: UserActiveCompanyChangeThunk
	// someOtherReducer: someOtherReducer
});

/**
 * Store RootState type definition
 */
export type RootState = ReturnType<typeof RootReducer>;

export { RootReducer, IRootState };
